<!-- =========================================================================================
  File Name: AddNewDataSidebar.vue
  Description: Add New Data - Sidebar component
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
    <vs-sidebar click-not-close position-right parent="body" default-index="1" color="primary" class="add-new-data-sidebar items-no-padding" spacer v-model="isSidebarActiveLocal">
      <div class="mt-6 flex items-center justify-between px-6">
        <h4>{{ Object.entries(this.data).length === 0 ? $t("AJOUTER_UNE") : $t("MODIFICATION_D") }} {{$t("CORPORATE")}}  </h4>
        <feather-icon icon="XIcon" @click.stop="isSidebarActiveLocal = false" class="cursor-pointer"></feather-icon>
      </div>
      <vs-divider class="mb-0"></vs-divider>
      <component :is="scrollbarTag" class="scroll-area--data-list-add-new" :settings="settings" :key="$vs.rtl">
        <div class="p-6">
          <p class="pt-4">{{ $t("denomination") }}<b style="color: #ff6141" >*</b> </p>
          <vs-input
            v-validate="'required'"
            data-vv-validate-on="blur"
            name="denomination"
            v-model="input.denomination"
            class="w-full" />
          <span class="text-danger text-sm" v-show="errors.has('denomination')">{{$t("denomination_error")}}</span>

          <p class="pt-4">{{ $t("legalStatus") }}<b style="color: #ff6141" >*</b> </p>
            <v-select
            name="legalStatus"
            v-validate="'required'"
            data-vv-validate-on="blur"
            :reduce="rep => rep.key"
            class="w-full" label="name"
            v-model="input.legalStatus"
            :options="status">
          </v-select>
          <span class="text-danger text-sm" v-show="errors.has('legalStatus')">{{$t("legalStatus_error")}}</span>
           
          <p class="pt-4">{{ $t("email") }}<b style="color: #ff6141" >*</b> </p>
          <vs-input
            v-validate="'required|email'"
            data-vv-validate-on="blur"
            name="email"
            v-model="input.email"
            class="w-full" />
          <span class="text-danger text-sm" v-show="errors.has('email')">{{$t("email_error")}}</span>
           
          <p class="pt-4">{{ $t("Phone") }}<b style="color: #ff6141" >*</b> </p>
          <vue-tel-input
              v-model="input.phoneNumber"
              placeholder="Telephone"
              :mode="national"
              :autoFormat="false"
              :enabledregionCode="true"
              :enabledFlags="true"
              :validCharactersOnly="true"
              data-vv-validate-on="blur"
              v-validate="'required|min:8|max:25'"
              @validate="yourValidationMethod"
              name="phoneNumber"
              class="w-full"
          >
          </vue-tel-input>
          <span class="text-danger text-sm" v-show="errors.has('phoneNumber')">{{$t("phoneNumber_error")}}</span>
        
           
          <p class="pt-4">{{ $t("address") }}<b style="color: #ff6141" >*</b> </p>
          <vs-input
            v-validate="'required'"
            data-vv-validate-on="blur"
            name="address"
            v-model="input.address"
            class="w-full" />
          <span class="text-danger text-sm" v-show="errors.has('address')">{{$t("address_error")}}</span>
           
          <p class="pt-4">{{ $t("activity") }}<b style="color: #ff6141" >*</b> </p>
          <vs-input
            v-validate="'required'"
            data-vv-validate-on="blur"
            name="activity"
            v-model="input.activity"
            class="w-full" />
          <span class="text-danger text-sm" v-show="errors.has('activity')">{{$t("activity_error")}}</span>
           
          <p class="pt-4">{{ $t("numberOfEmployees") }}<b style="color: #ff6141" >*</b> </p>
          <vs-input
            v-validate="'required'"
            data-vv-validate-on="blur"
            name="numberOfEmployees"
            v-model="input.numberOfEmployees"
            class="w-full" />
          <span class="text-danger text-sm" v-show="errors.has('numberOfEmployees')">{{$t("numberOfEmployees_error")}}</span>
           
          <p class="pt-4">{{ $t("region") }}<b style="color: #ff6141" >*</b> </p>
            <vs-select
            v-model="input.region"
            v-validate="'required'"
            data-vv-validate-on="blur"
            autocomplete
            clearable
            class="w-full "
            name="region"
          >
          <vs-select-item :key="item" :value="item.id" :text="item.name" v-for="item in regions" /></vs-select>
          
          <span class="text-danger text-sm" v-show="errors.has('region')">{{$t("region_error")}}</span>
           
           
        </div>
      </component>
  
      <div class="flex flex-wrap items-center p-6" slot="footer">
        <vs-button class="mr-6" @click="category_validate">{{$t("Soumettre")}}</vs-button>
        <vs-button type="border" color="danger" @click="isSidebarActiveLocal = false">{{$t("Annuler")}}</vs-button>
      </div>
    </vs-sidebar>
  </template>
  
<script>
    
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
  
const input_tempon = {
  denomination: '',
  legalStatus: '',
  email: '',
  phoneNumber: '',
  address: '',
  activity: '',
  numberOfEmployees: '',
  resourceContactPerson: '',
  region: ''
}
  
export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object,
      default: () => {}
    }
  },
  components: {
    VuePerfectScrollbar
  },
  data () {
    return {
      status: [
        {
          key: 'si',
          name: 'SI'
        },
        {
          key: 'eurl',
          name: 'EURL'
        },
        {
          key: 'sarl',
          name: 'SARL'
        },
        {
          key: 'sarlu',
          name: 'SARLU'
        },
        {
          key: 'sa',
          name: 'SA'
        },
        {
          key: 'sas',
          name: 'SAS'
        },
        {
          key: 'sasu',
          name: 'SASU'
        },
        {
          key: 'scop',
          name: 'SCOP'
        },
        {
          key: 'sca',
          name: 'SCA'
        },
        {
          key: 'scs',
          name: 'SCS'
        }
      ],
      subdivision:[],
      regions:[],
      input: JSON.parse(JSON.stringify(input_tempon)),
      settings: { // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: .60
      }
    }
  },
  watch: {
    isSidebarActive (val) {
      if (!val) return
      if (Object.entries(this.data).length === 0) {
        this.initValues()
        this.$validator.reset()
      } else {
        this.input = JSON.parse(JSON.stringify(this.data))
        this.input.region = this.input.region.id
        this.input.subdivision = this.input.subdivision.id
        this.initValues()
      }
    }
  },
  
  computed: {
    isSidebarActiveLocal: {
      get () {
        return this.isSidebarActive
      },
      set (val) {
        if (!val) {
          this.$emit('closeSidebar')
          // this.$validator.reset()
          // this.initValues()
        }
      }
    },
    scrollbarTag () { return this.$store.getters.scrollbarTag }
  },
  methods: {
    initValues () {
      if (this.data.id) return
      this.input = JSON.parse(JSON.stringify(input_tempon))
    },
    category_validate () {
      this.$validator.validateAll().then(result => {
        if (result) {
          this.submitData()
        }
      })
    },
  
    async submitData () {
      this.$vs.loading()
      const input = JSON.parse(JSON.stringify(this.input))
  
      let url = 'corporates/'
      let methods = 'post'
      const message = {
        error: this.$t('save_error'),
        success: this.$t('corporate_save')
      }
      if (input.id) {
        url += `${input.id}/`
        methods = 'put'
        message.success = this.$t('corporate_update')
      }
  
      this.$http[methods](url, input)
        .then((response) => {
          window.corporates.getAllcorporates()
          window.getPrendTaCom.success(message.success, response)
          this.$emit('closeSidebar')
          this.initValues()
        })
        .catch((error) => {
  
          const clefs = Object.keys(error.response.data)
          for (let i = 0; i < clefs.length; i++) {
            const item = clefs[i]
            let libelle = ''
            if (item === 'denomination') {
              libelle = this.$t('denomination')
            } else if (item === 'legalStatus') {
              libelle = this.$t('legalStatus')
            } else if (item === 'email')  {
              libelle = this.$t('email')
            } else if (item === 'phoneNumber')  {
              libelle = this.$t('Phone')
            } else if (item === 'address') {
              libelle = this.$t('address')
            } else if (item === 'activity') {
              libelle = this.$t('activity')
            } else if (item === 'username')  {
              libelle = this.$t('username')
            } else if (item === 'numberOfEmployees')  {
              libelle = this.$t('numberOfEmployees')
            } else if (item === 'subdivision') {
              libelle = this.$t('subdivision')
            } else if (item === 'region') {
              libelle = this.$t('region')
            }
            for (let j = 0; j < error.response.data[item].length; j++) {
              window.getPrendTaCom.error(`${libelle} :  ${error.response.data[item][j]}`)
            }
          }
  
          window.getPrendTaCom.error(message.error)
        })
    },

    getAllregions () {
      this.$vs.loading()
      this.$http.get('regions/')
        .then((response) => {
          this.regions = response.data
          this.$vs.loading.close()
        })
        .catch(() => {
          this.$vs.loading.close()
        })
    }
  
  },
  created () {
    this.getAllregions()
  }
}
</script>
  
  <style lang="scss" scoped>
  .add-new-data-sidebar {
    ::v-deep .vs-sidebar--background {
      z-index: 52010;
    }
  
    ::v-deep .vs-sidebar {
      z-index: 52010;
      width: 400px;
      max-width: 90vw;
  
      .img-upload {
        margin-top: 2rem;
  
        .con-img-upload {
          padding: 0;
        }
  
        .con-²-upload {
          width: 100%;
          margin: 0;
        }
      }
    }
  }
  
  .scroll-area--data-list-add-new {
    // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
    height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);
  
    &:not(.ps) {
      overflow-y: auto;
    }
  }
  </style>
  